<div class="certificate-block"
  [style.top]="block.top"
  [style.left]="block.center ? '0' : block.left"
  [style.right]="block.center ? '0' : false"
  [style.margin-left]="block.center ? 'auto' : '0'"
  [style.margin-right]="block.center ? 'auto' : '0'"
  [style.width]="block.image_width"
  [style.height]="block.image_height"
  [style.font-family]="block.font | toFontFamily"
  [style.font-weight]="block.font_weight"
  [style.font-size]="block.font_size | convertFontSizeToEm"
  [style.text-align]="block.align"
  [style.color]="block.color"
  [style.z-index]="block.z_index"
  [class.bold]="block.bold"
  [class.underline]="block.underline"
  [class.italic]="block.italic"
  [class.uppercase]="block.uppercase"
  [class.line]="block.line_width"
  [class.custom-image-attribute]="block.custom_image_attribute"
  [class.fixed-min-size]="!!block.font && !!block.image_height"
  [class.justify-left]="block.align === 'left'"
  [class.justify-center]="!block.align || block.align === 'center'"
  [class.justify-right]="block.align === 'right'"
  [class.vertical-align-top]="block.vertical_align === 'top'"
  [class.vertical-align-center]="!block.vertical_align || block.vertical_align === 'center'"
  [class.vertical-align-bottom]="block.vertical_align === 'bottom'">

  <!-- Text content, with custom-attribute replacement -->
  <accredible-block-text *ngIf="block.model"
    [block]="block"
    [partialCredential]="partialCredential"
    (updateProcessing)="onUpdateProcessing($event);">
  </accredible-block-text>

  <accredible-block-image *ngIf="block.image_url || block.custom_image_attribute"
    [block]="block"
    [partialCredential]="partialCredential"
    (updateProcessing)="onUpdateProcessing($event);">
  </accredible-block-image>

  <!-- QR Code -->
  <accredible-qrcode *ngIf="block.qr_code"
    [block]="block"
    [partialCredential]="partialCredential">
  </accredible-qrcode>

  <!-- Line -->
  <div *ngIf="block.line_width"
    class="block-line">
    <div [ngClass]="block.orientation"
      [style.border-width]="'0'"
      [style.border-color]="block.color"
      [style.border-top-width]="block.orientation === 'horizontal' ? block.line_width : '0'"
      [style.border-left-width]="block.orientation === 'vertical' ? block.line_width : '0'"
      [style.border-style]="block.line_style">
    </div>
  </div>
</div>
