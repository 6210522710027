<label [ngClass]="{'has-error': required && !formControlRef.value, 'disabled': disabled, 'show-full-text': showFullText }">
  <input type="checkbox"
    class="accredible-checkbox"
    [attr.data-cy]="dataCy"
    [formControl]="formControlRef"
    [attr.aria-label]="label"
    [attr.aria-checked]="formControlRef.value"
    [attr.aria-describedby]="hint || ''"
    [attr.aria-disabled]="disabled"
    [attr.tabindex]="tabIndex"
    (change)="onChange($event)"/>

  <span class="input-box">
    <accredible-icon class="font-xs-regular"
      [icon]="'check'">
    </accredible-icon>
  </span>

  <span class="input-text font-normal-regular" [innerHTML]="label"></span>
</label>

<div *ngIf="hint"
  class="hint font-s-regular">
  {{ hint }}
</div>

